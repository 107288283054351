import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./styles.scss";
import koyoLogo from "../assets/icons/Koyo-Logo-desktop.svg";
import nightLogo from "../assets/icons/nightLogo.svg";
import dayLogo from "../assets/icons/dayLogo.svg";

import { Button } from "react-bootstrap";
import { useTheme } from './ThemeContext';

const Navbar: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
        <div className="container-fluid">
          <span className="navbar-brand" >
            <img src={koyoLogo} alt="Logo" />
          </span>

          <ul className="navbar-nav">
            <li className="nav-item">
            <Button onClick={toggleTheme} className="bg-transparent theme-toggle-btn border-0">
          {theme === 'dark' ? <img src={dayLogo} alt="Logo" /> : <img src={nightLogo} alt="Logo" />}
        </Button>
            </li>
            <li className="nav-item">
            <a
              href="https://tally.so/r/wdlZGd"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              target="_blank"
              rel="noreferrer"
            >
              <Button className={`bg-transparent ${theme === 'dark' ? 'text-light' : 'text-dark'} survey-btn`}>
                Take a survey <i className="bi bi-arrow-up-right"></i>
              </Button>
            </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="navbarBorder" style = {theme === 'dark' ? {borderColor: '#2F3237'} : {borderColor: '#f1f1f3'} } ></div>
    </div>
  );
};

export default Navbar;
