import "./App.css";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import { ThemeProvider } from "./components/ThemeContext";

const App = () => {
  return (
    <div className="App">
      <ThemeProvider>
        <Navbar />
      <Home />
      </ThemeProvider>
    </div>
  );
};

export default App;
