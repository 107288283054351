import React from "react";
import "./loader.scss";

import * as animationData from "../assets/KoyoLoaderwithtext.json";
import Lottie from 'react-lottie';

export const Loader: React.FC = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <div className="loader">
      <Lottie options={defaultOptions} style={{width: '20%', height: '20%'}}/>
    </div>
  );
};
