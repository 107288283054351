import React from "react";
import { useTheme } from './ThemeContext';

interface InfoContentProps {
  image: string;
  title: string;
  text: string;
  bColor: string;
}

export const InfoContent: React.FC<InfoContentProps> = ({
  image,
  title,
  text,
  bColor
}) => {
  const { theme } = useTheme();
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', border: 'none', borderColor: bColor}}>
      
        <div className={`card mb-3 ${theme === 'dark' ? 'text-light' : 'text-dark'}`} style={{ backgroundColor: bColor,height: '90%', maxHeight:'30vh', maxWidth: '40vh', margin:10, borderColor: bColor, fontWeight:300, fontSize:13 }}>
          <div className={"card-body"} >
            <img src={image} alt="" />
            <h5 className="card-title mt-3">{title}</h5>
            <p className="card-text" 
            style = {theme === 'dark' ? {color: '#ffffff'} : {color: '#676c78'}}
            >{text}</p>
          </div>
       
      </div>
    </div>
  )};

