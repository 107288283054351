import React, { useEffect, useState } from "react";
import Typed from "typed.js";
import { Button } from "react-bootstrap";
import { Modal } from "bootstrap";
import people from "../assets/img/people.svg";
import ReactFlagsSelect from "react-flags-select";
import { InfoContent } from "./InfoContent";
import { SubmitModal } from "./SubmitModal";
import interactiveIcon from "../assets/img/Vector.svg";
import languageIcon from "../assets/img/languageIcon.svg";
import libraryIcon from "../assets/img/libraryIcon.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "./Loader";
import COUNTRY_CODES from "../countryCodeMapping"; //COUNTRY_CODES stores a collection of country codes and country names as key:pair mappings
import { useTheme } from "./ThemeContext";

const Home: React.FC = () => {
  const { theme } = useTheme();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [selected, setSelected] = useState<string>(""); //Intial state tracker for seelcted country from react-flags it would be ideal to use this instead => [ selectedCountry, setSelectedCountry ]
  /******************************************/
  //UseState to collect data entered by user//
  /******************************************/
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country: "",
  });

  // const onSelect = (code: string): void => setSelected(code); //This is unused becase of my handleSelectCountry Function below
  const [isLoading, setIsLoading] = useState(true);
  const [waitListNumber, setWaitListNumber] = useState(0);
  const [inputLengthClass, setInputLengthClass] = useState<string>("");
  const el = React.useRef(null);

  /*******************************************************************/
  //handleChange function to set formData to new data entered by user//
  //Handle input changes for first/last name and email               //
  /******************************************************************/
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  /*******************************************************************/
  //handleSelectCountry function to set country data selected by user//
  //I think that is what is being refrenced in onSelect variable above//
  //but I cant really understand the implementation...                //
  /*******************************************************************/
  const handleSelectCountry = (countryCode: string) => {
    const countryName = COUNTRY_CODES[countryCode] || countryCode; // Get country name from COUNTRY_CODES, as they are key val pairs. E.g. COUNTRY_CODES[NG] == Nigeria (country name)
    setSelected(countryCode);
    // Update the country in formData state
    setFormData({
      ...formData,
      country: countryName,
    });

    if (countryName.length <= 13) {
      setInputLengthClass("shortInput");
    } else if (countryName.length < 30) {
      setInputLengthClass("mediumInput");
    } else {
      setInputLengthClass("longInput");
    }
  };
  const [timer, setTimer] = useState(2);

  useEffect(() => {
    // Wait for the window to load fully (all images, CSS, etc.)
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Simulate an async operation (e.g., data fetching)
    setTimeout(() => {
      setIsLoading(false); // Stop loading after 3 seconds
      clearInterval(countdown); // Clear the countdown timer
    }, 3000);
    // Cleanup listener on component unmount
    return () => clearInterval(countdown);
  }, []);

  const validateForm = () => {
    let isValid = true;

    if (!formData.first_name.trim()) {
      toast.error("First name is required");
      isValid = false;
    }

    if (!formData.last_name.trim()) {
      toast.error("Last name is required");
      isValid = false;
    }

    if (!formData.email.trim()) {
      toast.error("Email is required");
      isValid = false;
    } else if (!emailRegex.test(formData.email.trim())) {
      toast.error("Please enter a valid email address.");
      isValid = false;
    }

    if (!formData.country.trim() || !selected) {
      toast.error("Country is required");
      isValid = false;
    }

    return isValid;
  };

  const showModal = () => {
    const modalElement = document.getElementById("submitModal")!;
    const modal = Modal.getOrCreateInstance(modalElement);
    modal.show();
  };

  useEffect(() => {
    const options = {
      strings: [`Welcome`, `Ọb\'okhian`, `Nnọọ`, `E̩ káàbò̩`],
      typeSpeed: 60,
      backSpeed: 60,
      loop: true,
    };

    const typed = new Typed(el.current, options);

    return () => {
      typed.destroy();
    };
  }, []);

  /************************************************************/
  //handleSubmit function to submit form data selected by user//
  //formData includes first_name,last_name, email, country   //
  //this is sent as POST to server API route */join-waitlist //
  /************************************************************/
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      // Perform form submission logic here
      // console.log("Form submitted with data:", formData);

      axios
        .post("https://koyoapp.com/join-waitlist/", formData)
        .then((response) => {
          // console.log("User data saved:", response.data);
          // console.log("User ID:", response.data.id); //this is the Id passed as response from backend
          setWaitListNumber(response.data.id);
          // console.log("value: ", response.data.id);

          // Clear the form fields
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            country: "",
          });
          setSelected("");
          showModal();
        })
        .catch((error) => {
          // console.error("Error saving data:", error);
          // console.log(error.response.data.error);
          const duplicateEmail: string = error.response.data.error;
          if (duplicateEmail === "Email already in use") {
            // console.log(error.response.data.error);
            toast.error(
              "Email has already been used to register for waitlist!"
            );
          }
        });
    }
  };

  return (
    <>
      {isLoading && <Loader />} {/* Show loader if isLoading is true */}
      <div className="container mt-5">
        <Button className="border-0 rounded-pill oct-btn">
          <i className="bi ">👋🏾</i>{" "}
          <span
            className="mb-5 welcome-style"
            // style={{ color: "#D40100" }}
            style={
              theme === "dark" ? { color: "#F86833" } : { color: "#D40100" }
            }
            ref={el}
          />
        </Button>
        <h3 className="text-center mb-2 pt-4" style={{ fontWeight: 500 }}>
          Bringing Africans back to their roots. <br />
          One language at a time.
        </h3>
        <form onSubmit={handleSubmit} className="mt-4">
          {/* <div className="container-fluid"> */}
          <div className="mb-3 row justify-content-center">
            <div className="d-flex justify-content-center mb-md-0 align-items-center pill-input">
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                placeholder="First Name"
                className="form-control border-0"
                autoComplete="off"
              />
            </div>
          </div>
          {/* </div> */}
          <div className="mb-3 row justify-content-center">
            <div className="d-flex justify-content-center mb-md-0 align-items-center pill-input">
              {/*<i className="bi bi-person"></i>*/}
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                placeholder="Last Name"
                className="form-control border-0"
                autoComplete="off"
              />
            </div>
          </div>

          <div className="mb-3 row justify-content-center ">
            <div className="d-flex justify-content-center mb-md-0 align-items-center pill-input">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                className="form-control border-0"
                autoComplete="off"
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className={`${inputLengthClass}`}
              style={{
                display: "flex",
                justifyContent: "center",
                maxWidth: "400px",
              }}
            >
              {/*<i className="bi bi-globe-europe-africa mx-3 "></i>*/}
              {/* <img src={globeIcon} alt="a globe" className="ml-3" /> */}
              <ReactFlagsSelect
                //className="mx-4"
                selected={selected}
                onSelect={handleSelectCountry}
                showSelectedLabel={true}
                showSecondarySelectedLabel={false}
                selectedSize={16}
                showOptionLabel={true}
                showSecondaryOptionLabel={false}
                optionsSize={16}
                placeholder="What country are you in?"
                searchable={true}
                searchPlaceholder="Search for country"
                fullWidth={true}
                disabled={false}
                customLabels={COUNTRY_CODES}
                alignOptionsToRight
              />
            </div>
          </div>

          <div
            className="mb-3 row justify-content-center "
            style={{ marginTop: 25 }}
          >
            <Button
              type="submit"
              className="border-0 transparent-button pill-input submit-btn"
              data-bs-target="#submitModal"
              style={{ height: 40 }}
            >
              Join the waitlist
            </Button>
            <SubmitModal waitListNumber={waitListNumber} />
          </div>

          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </form>

        <img
          src={people}
          alt="illustration 1"
          className="img-fluid svg-image"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <InfoContent
            image={interactiveIcon}
            title={"Interactive Lessons"}
            text={
              "Enjoy learning grammar and vocabulary effortlessly with our fun and easy-to-understand modules"
            }
            bColor={`${theme === "dark" ? "#6B3D1E" : "#FFF4ED"}`}
          />
          <InfoContent
            image={languageIcon}
            title={"Languages Dictionary"}
            text={
              "Learn thousands of native words and their English translations."
            }
            // bColor={"#E9F6F7"}
            bColor={`${theme === "dark" ? "#0E4347" : "#E9F6F7"}`}
          />
          <InfoContent
            image={libraryIcon}
            title={"Rich video library"}
            text={
              "Watch and learn! Experience African Languages come alive through our video library."
            }
            // bColor={"#FDEEF1"}
            bColor={`${theme === "dark" ? "#642330" : "#FDEEF1"}`}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
